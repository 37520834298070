export const isDraftHasValue = (draft_comment) => {
  if(draft_comment){
    if(draft_comment?.description || draft_comment?.parent_id || draft_comment.attachment_ids?.length > 0) return true;
  } else {
    return false
  }
}

export const hasDraft = (draft_comments = []) => {
  return draft_comments.some(draft_comment => 
      isDraftHasValue(draft_comment)
  )
}