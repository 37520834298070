import { userConstants, notificationConstants } from '../constants';
import { makeGETRequest } from '../utils/Axios';

export const dashboard_userActions = {
  DashboardnitialData,
  CommonInitialData,
  getCurrentUserPermisson
};

export function DashboardnitialData() {
  return dispatch => {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest('/api/v1/home')
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.DASHBOARD_INITIAL_DATA, payload: response})
        }
      })
    }catch{

    }
  }
}

export function CommonInitialData() {
  return dispatch => {
    try{
      dispatch({ type: userConstants.COMMON_INITIAL_DATA_LOADING });
      makeGETRequest('/api/v1/get_common_details')
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.COMMON_INITIAL_DATA, payload: response})
          dispatch({type: userConstants.CURRENT_USER_DATA, payload: response.data.current_user})
          dispatch({type: userConstants.PENDING_NOTIFICATION_COUNT, payload: response.data.notification_count})
          dispatch({type: userConstants.WORKSPACES_DATA, payload: response.data.workspaces})
          dispatch({type: notificationConstants.UPDATE_NOTIFICATION_RING, payload: response.data.enable_notification_ring})
          dispatch({type: notificationConstants.UNREAD_NOTIFICAITON_COUNT, payload: response.data.unread_notification_count})
        }
      })
    }catch{

    }
  }
}

export function getCurrentUserPermisson() {
  return dispatch => {
   try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/permissions`)
      .then(response => {
        dispatch({
          type: userConstants.CURRENT_USER_PERMISSION,
          payload: response.data
        })
      })
    }catch{

    }
  }
}
