import { useState, useContext, useRef } from 'react';
import { useDispatch } from "react-redux";
import { UserAvatar, FileAttachment, ImageAttachment, Popup } from '../components';
import CommentApprovalSendIcon from '../images/icons/white/submit.svg';
import CommentRevisionIcon from '../images/icons/reject.svg';
import CommentCancelIcon from '../images/icons/white/cancel.svg';
import CommentCheckIcon from '../images/icons/comment-approved.svg';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import { ChatContext } from '../contexts/ChatContext';
import { commentActions } from '../actions';
import { useMinuteCheck } from '../utils';

function MessageBubble(props) {
  const {
    own,
    extra_heading_content,
    user_name,
    user_name_initials,
    user_icon,
    message,
    date,
    event_type,
    selectCommentToReply,
    id,
    highlighted,
    is_a_reply,
    attachments,
    workspace_id,
    organization_id,
    content_group_id,
    setImagePopUp,
    user_id,
    renderParentComment,
    parent_id,
    data
  } = props;

  const [selected, setSelected] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const messageRef = useRef();
  const dispatch = useDispatch();
  const created_at = new Date(data?.created_at);
  const minutesPassed = useMinuteCheck(created_at);

  const handleCloseDeletePopup = () => {
    setShowPopupDelete(false);
  }

  const handleCommentDelete = () => {
    const succesCallback = (response) => {
      handleCloseDeletePopup()
    }

    dispatch(commentActions.deleteComment(workspace_id, content_group_id, id, succesCallback))
  }

  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  const {
    can_access_comment,
    setCommentToEdit,
    commentToEdit,
    handleEditComment
  } = useContext(ChatContext);

  const full_message = () => {
    if(typeof message === 'undefined') return '';

    if ( data?.created_at === data?.updated_at ){
      return message
    } else if ( data?.deleted_at === null ) {
      return message + '<span class="message-edited"> (edited)</span>'
    } else {
      return message
    }
  }

  const tooltipHandler = (text) => {
    return {
      'data-toggle': "tooltip",
      title: text
    }
  }

  return (
    <div
      className={
        `message ${ own ? " message--own" : "message--other"}
        ${selected ? 'hovered' : null}
        ${highlighted ? 'highlighted' : null }`
      }
      ref={messageRef}
    >
      <div className="d-flex">
        <div className="message-user">
          <UserAvatar
            icon={ user_icon }
            name={ user_name }
            name_initials={ user_name_initials }
            size="40"
            user_id={user_id}
            openUserSummary={true}
            workspace_id={workspace_id}
            organization_id={organization_id}
          />
        </div>
        <div className="px-2" style={{width: "calc(100% - 40px)"}}>
          <div className="">
            <div className="description-container">
              { extra_heading_content }
              { user_name &&
                <div className="description__user mb-1">
                  { user_name }
                </div>
              }
            </div>
          </div>

          <div className="description-container">
            {
              data?.parent &&
              <div className="mb-2">
                { renderParentComment(data) }
              </div>
            }

            {
              (message || event_type) &&

              <div className={ "description" + ( event_type ? " description--with-action" : "" ) }>
                <span dangerouslySetInnerHTML={{ __html:
                  linkifyHtml((full_message() || ''), { target: '_blank' })
                }} />

                <span
                  className={"action-icon ml-2" + (true ? "" : " d-none")  }
                  style={ message === '' || message === null ? { right: 'initial' } : null }
                >
                  { event_type == "request_revision" &&
                    <img src={CommentRevisionIcon} title="Requested Revisions" />
                  }

                  { event_type == "submit_for_approval" &&
                    <div className="icon-container green">
                      <img src={CommentApprovalSendIcon} title="Submitted for Approval"/>
                    </div>
                  }

                  { event_type === "void_submission" &&
                    <div className="icon-container red">
                      <img src={CommentCancelIcon} title="Voided Submission"/>
                    </div>
                  }

                  { event_type == "approve" &&
                    <img src={CommentCheckIcon} title="Approved" />
                  }

                  { event_type == "accept_request" &&
                    <img src={CommentCheckIcon} title="Accepted Request" />
                  }

                  { event_type == "reject_request" &&
                    <img src={CommentRevisionIcon} title="Rejected Request" />
                  }
                </span>
              </div>
            }

            {
              attachments && attachments.length > 0 &&
              <div className="attachments-container">
                <div>
                  {
                    attachments.filter((attach) => attach.file_thumbnail).length > 0 &&
                      <div
                        className={`pr-0 mb-2`}
                      >

                        {
                          arrayChunk(attachments.filter((attach) => attach.file_thumbnail), 3).map((row, i) => (
                            <div key={i} className="mb-1 d-flex">
                              {row.map((attachment, i) => (
                                <ImageAttachment
                                  filename={ attachment.filename }
                                  thumbnail={ attachment.file_thumbnail }
                                  image_url={ attachment.image_url }
                                  attachment_id={ attachment.id }
                                  workspace_id={ workspace_id }
                                  content_group_id={ content_group_id }
                                  comment_id={ id }
                                  setSelected={ setSelected }
                                  key={ attachment.id }
                                  own={ own }
                                  viewable={ true }
                                  dynamic_icon={ true }
                                  confirm_deletion={true}
                                  className={attachments.filter((attach) => attach.file_thumbnail).length > 2 ? null : 'by_2'}
                                />
                              ))}
                            </div>
                          ))
                        }
                      </div>
                  }

                  {
                    attachments.filter((attach) => attach.file_thumbnail == null || attach.file_thumbnail == false).map((attachment, index) =>(
                      <div
                        key={ attachment.id }
                        className="pr-0"
                      >
                        <FileAttachment
                          filename={ attachment.filename }
                          workspace_id={ workspace_id }
                          content_group_id={ content_group_id }
                          comment_id={ id }
                          attachment_id={ attachment.id }
                          setSelected={ setSelected }
                          own={ own }
                          downloadable={ true }
                          confirm_deletion={true}
                        />
                      </div>
                    ))
                  }

                </div>
              </div>
            }

            <div className="message-date">
              {
                can_access_comment && data?.deleted_at === null &&
                <>
                  <div 
                    className={`message-reply-button mr-2 ${commentToEdit?.id ? 'disabled' : undefined}`} 
                    onClick={() => commentToEdit?.id ? undefined : selectCommentToReply(id, messageRef)}
                  >
                    Reply
                  </div>

                  { (own && !minutesPassed) &&
                    <>
                      <div 
                        className={`message-reply-button mr-2 ${commentToEdit?.id ? 'disabled' : undefined}`} 
                        onClick={() => commentToEdit?.id ? undefined : handleEditComment(data)}
                        {...tooltipHandler('Available within 5 minutes')}
                      >
                        Edit
                      </div>

                      <div 
                        className={`message-reply-button mr-2 ${commentToEdit?.id ? 'disabled' : undefined}`} 
                        onClick={() => commentToEdit?.id ? undefined : setShowPopupDelete(true)}
                        {...tooltipHandler('Available within 5 minutes')}
                      >
                        Delete
                      </div>
                    </>
                  }
                </>
              }



              { date }
            </div>
          </div>
        </div>
      </div>

      {showPopupDelete &&
        <Popup
          handleClose={handleCloseDeletePopup}
          handleDelete={handleCommentDelete}
        />
      }
    </div>
  );
}

export { MessageBubble };
