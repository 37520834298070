import { Row, Col, Image } from 'react-bootstrap';
import React, { useState, useEffect, useContext, useReducer } from 'react';
import { commentAttachmentActions } from '../actions';
import { useDispatch, connect } from 'react-redux';
import { ChatContext } from '../contexts/ChatContext'
import { Popup } from './Popup';

function ImageAttachment(props) {
  const dispatch = useDispatch();
  const {
    filename,
    image_url,
    thumbnail,
    workspace_id,
    content_group_id,
    comment_id,
    attachment_id,
    setSelected,
    own,
    viewable,
    destroyable,
    CustomHandleDelete,
    dynamic_icon,
    className,
    show_x_btn,
    confirm_deletion,
    hide,
    deleted_comment_attachment
  } = props

  const [thumbnail_cached, setThumbnailCached] = useState(false);
  const [cached_thumbnail, setCachedThumbnail] = useState();
  const [hidden, setHidden] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
 
  useEffect(() => {
    if (thumbnail_cached) {
      return null;
    }

    if (thumbnail) {
      setCachedThumbnail(thumbnail)
      setThumbnailCached(true);
    }
  }, [thumbnail]);

  useEffect(() => {
    if(deleted_comment_attachment && deleted_comment_attachment?.id === attachment_id){
      setHidden(true);
    }
  }, [deleted_comment_attachment]);

  const { activeTab } = useContext(ChatContext);

  const handleCloseDeletePopup = () => {
    setShowPopupDelete(false)
  }

  const handleDelete = (e) => {
    if (CustomHandleDelete) {
      CustomHandleDelete();
    } else {
      e.stopPropagation();
      setSelected(false);

      if (own && destroyable){
        dispatch(
          commentAttachmentActions.destroyCommentAttachment(
            workspace_id,
            content_group_id,
            comment_id,
            attachment_id,
            activeTab
          )
        );
      }
    }
  }

  const handleMouseOver = () => {
    if (setSelected) setSelected(true);

  };

  const handleMouseOut = () => {
    if (setSelected) setSelected(false);
  };

  const viewImage = () => {
    if (viewable) {
      if (image_url) window.open(image_url, '_blank');
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if(confirm_deletion){
      setShowPopupDelete(true) 
    }else {
      handleDelete(e)
    }
  }

  if (hidden || hide){
    return null
  }

  return (
    <div 
      className={`attachment-container mr-2 image ${dynamic_icon ? 'dynamic' : null} ${className}`}
    >
      <div
        className={`attachment-thumbnail text-center ${show_x_btn ? 'show_x_btn' : undefined}`}
        onClick={() => viewImage()}
        onMouseOver={handleMouseOver}
        onMouseOut ={handleMouseOut}
      >
        <Image src={cached_thumbnail} loading='lazy' fluid/>
        {
          own && destroyable &&
          <div className="close-button" onClick={handleDeleteClick}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        }
      </div>

      {showPopupDelete &&
        <Popup
          handleClose={handleCloseDeletePopup}
          handleDelete={handleDelete}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    deleted_comment_attachment: state.comment.deleted_comment_attachment,
  };
};

export default connect(mapStateToProps, { })(ImageAttachment);