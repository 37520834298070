import React, { useEffect } from "react";
import "./contentForm.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userActions } from "../../../actions";
import { contentActions } from '../../../actions';
import { Col, Card } from "react-bootstrap";


function ContentShow(props) {
  const { workspace_id, content_group_id, content_id } = useParams();
  const { currentContent} = props
  const dispatch = useDispatch();
  const content = useSelector(state => state.content.currentContent);
  const workspace = useSelector(state => state.content.currentWorkSpace);
  const content_group = useSelector(state => state.content.currentContentGroup);
  const content_attachment = content && content.content_attachment;
  let selected_content = null;

  useEffect(() => {
    const { state } = props.location

    if(content_id){
      const showContentParams = {
        workspace_id,
        content_group_id,
        id: content_id
      };

      dispatch(contentActions.showContent(showContentParams));
    }else {
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="col-lg-6 pl-0">
        {content ?
          <div style={{ marginRight:0, marginLeft:0 }}>
            <Col xs={12} className="pl-0">
              <div>
                <Card style={{ marginBottom: "2rem" }}>
                  {content_attachment ?
                      <Card.Img variant="top" src={content_attachment}></Card.Img>
                    :
                      null
                  }
                </Card>
              </div>
            </Col>
            <Col xs={12} className="pl-0">
            </Col>
          </div>
          :
            <div className="centered">
              <h2>No Content</h2>
              <p>to preview.</p>
            </div>
          }
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.content.loading,
    currentContent: state.content.currentContent
  };
};

export default connect(mapStateToProps, { userActions })(ContentShow);
