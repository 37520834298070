function DraftCommentIcon({ 
  className, 
  color = '#fe543d', 
  height = 13, 
  width = 13 
}) {

  return (
    <svg 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      viewBox="0 0 14 13.998"
      width={width}
      height={height}
      style={{minWidth: width, minHeight: height}}
      className={className}
    >
      <path 
        fill={color} 
        d="M1.556,12.442h1.108l7.602-7.602-1.108-1.108L1.556,11.334v1.108ZM.778,13.998c-.22,0-.405-.075-.554-.224-.149-.149-.224-.334-.224-.554v-1.886c0-.208.039-.405.117-.593.078-.188.188-.353.331-.496L10.266.447c.156-.142.327-.253.516-.331s.386-.117.593-.117.408.039.603.117c.194.078.363.194.505.35l1.069,1.089c.156.142.269.311.341.505s.107.389.107.583c0,.208-.036.405-.107.593s-.185.36-.341.516L3.754,13.551c-.142.142-.308.253-.496.33s-.386.117-.593.117H.778ZM12.443,2.644l-1.089-1.089,1.089,1.089ZM9.702,4.297l-.544-.564,1.108,1.108s-.564-.544-.564-.544Z"
      />
    </svg>
  );
}

export { DraftCommentIcon };
