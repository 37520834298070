import { createContext, useEffect, useRef } from 'react'
import { AudioContext, isSupported } from 'standardized-audio-context';

export const SoundContext = createContext();

export function AudioProvider({ children }) {
  const audioContext = useRef(null) ;
  const audioContextReadyRef = useRef(false)
  const needSoundRef = useRef(false);

  const activateSound = () => {
    var new_context = new AudioContext();
    window.context = new_context

    if (window.context) {
       window.context.resume().then(() => {
        audioContextReadyRef.current = true;
        console.log('Activated sound')
      });
    }
  }

  // Activates audio if user clicked anywhere inside website
  // Note: Elements with event.stopPropagation() may not activate this
  useEffect(() => {
    var context = new AudioContext();

    window.onload = function() {
      window.context = context;
    }

    isSupported().then((isSupported) => {
      if (isSupported) {
          // yeah everything should work
      } else {
          // oh no this browser seems to be outdated
          alert('Browser seems to be outdated')
      }
    }).catch(() => {
      alert('The browser support could not be determined');
    });
    
    document.body.addEventListener('click', activateSound);

    return function cleanup() {
      document.body.removeEventListener('click', activateSound);
    } 
  },[]);

  let sound_values ={
    audioContext: audioContext.current, 
    audioContextReadyRef: audioContextReadyRef,
    needSoundRef: needSoundRef
  }

  return (
    <>
      <SoundContext.Provider value={sound_values}>
        {children}
      </SoundContext.Provider>
    </>
  )
}