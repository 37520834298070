import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function content(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.REMOVE_CONTENT_GROUP: {
      return {
        ...state,
        loading: false,
        currentContentGroup: null
      };
    }

    case userConstants.CONTENT_LOADING: {
      return {
        ...state,
        content_loading: true
      };
    }

    case userConstants.CONTENT_FAILURE: {
      return {
        ...state,
        loading: false,
        content_loading: false,
        error:action.payload
      };
    }

    case userConstants.NEW_CONTENT: {
      return {
        ...state,
        currentWorkSpace: action.payload.data.workspace,
        currentContentGroup: action.payload.data.content_group,
        contents: action.payload.data.contents,
        loading: false
      }
    }

    case userConstants.CONTENT_SHOW: {
      return {
        ...state,
        currentContent: action.payload.data.first_record,
        currentContentGroup: action.payload.data.content_group,
        currentWorkSpace: action.payload.data.workspace,
        loading: false,
        content_loading: false
      };
    }

    case userConstants.CONTENT_VERSION: {
      return {
        ...state,
        currentContent: action.payload.data.content,
        currentContentGroup: action.payload.data.content_group,
        currentWorkSpace: action.payload.data.workspace,
        versions: action.payload.data.versions,
        loading: false
      };
    }

    case userConstants.CONTENT_VERSION_SHOW: {
      return {
        ...state,
        currentContent: action.payload.data.content,
        currentContentGroup: action.payload.data.content_group,
        currentWorkSpace: action.payload.data.workspace,
        currentVersion: action.payload.data.version,
        loading: false
      };
    }


    case userConstants.CONTENT_REQUEST: {
      return {
        ...state,
        editContent: null,
        loading: false
      };
    }

    case userConstants.GET_CONTENT:{
      return{
        ...state,
        contents: action.payload.data.data,
        can_add: action.payload.data.can_add,
        currentContentGroupWorkspaceForChat: action.payload.data.content_group_workspace_for_chat,
        currentContentGroup: action.payload.data.content_group,
        assigned_users: action.payload.data.assigned_users,
        can_assign_user: action.payload.data.can_assign_user,
        can_access_submit_approval: action.payload.data.can_access_submit_approval,
        can_access_accept_revision: action.payload.data.can_access_accept_revision,
        can_access_reject_revision: action.payload.data.can_access_reject_revision,
        can_access_add_change_date: action.payload.data.can_access_add_change_date,
        can_access_accept_request_revision: action.payload.data.can_access_accept_request_revision,
        can_access_content_update: action.payload.data.can_access_content_update,
        can_access_content_create: action.payload.data.can_access_content_create,
        can_access_team_comment: action.payload.data.can_access_team_comment,
        can_access_show_team_comment: action.payload.data.can_access_show_team_comment,
        can_access_content_versions: action.payload.data.can_access_content_versions,
        can_access_content_hide: action.payload.data.can_access_content_hide,
        can_access_content_destroy: action.payload.data.can_access_content_destroy,
        can_access_content_comment: action.payload.data.can_access_content_comment,
        can_access_content_group_archive: action.payload.data.can_access_content_group_archive,
        can_access_assigned_user: action.payload.data.can_access_assigned_user,
        can_access_move_content_board: action.payload.data.can_access_move_content_board,
        can_access_show_content_details: action.payload.data.can_access_show_content_details,
        can_access_edit_label: action.payload.data.can_access_edit_label,
        can_sort_attachments: action.payload.data.can_sort_attachments,
        loading: false,
        content_loading: false
      }
    }

    case userConstants.GET_AUTHORIZED_USERS:{
      return{
        ...state,
        authorized_users: action.payload.data.authorized_users,
        loading: false
      }
    }

    case userConstants.EDIT_CONTENT:{
      return {
        ...state,
        editContent: action.payload.data.first_record,
        currentWorkSpace: action.payload.data.workspace,
        currentContentGroup: action.payload.data.content_group,
        contents: action.payload.data.contents,
        loading: false,
        content_loading: false
      }
    }

    case userConstants.DELETE_CONTENT:{
      let id = action.payload.data.data.id
      return {
        ...state,
        contents: state.contents.filter(item => item.id !== id),
        loading: false
      }
    }

    case userConstants.SUBMIT_CONTENTGROUP:{
      return {
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data
      }
    }

    case userConstants.VOID_SUBMIT_CONTENTGROUP:{
      return {
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data
      }
    }

    case userConstants.APPROVE_CONTENTGROUP:{
      return {
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data
      }
    }

    case userConstants.REJECT_CONTENTGROUP:{
      return {
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data
      }
    }

    case userConstants.REJECT_REQUEST_CONTENTGROUP:{
      return{
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data
      }
    }

    case userConstants.ACCEPT_REQUEST_CONTENTGROUP:{
      return{
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data
      }
    }

    case userConstants.UPDATE_CONTENT:{
      return {
        ...state,
        loading: false,
        content_loading: false
      }
    }

    case userConstants.CONTENT_INLINE_UPDATE:{
      return {
        ...state,
        loading: false,
        currentContentGroup: action.payload.data.data,
        currentContent: action.payload.data.contents[0],
        contents: action.payload.data.contents,
        content_loading: false
      }
    }

    case userConstants.CONTENT_INLINE_EDIT: {
      let contentGroupDetail = action.payload.data
      let resultUpdateInlineEdit = ""
      if(state.currentContentGroup && state.currentContentGroup) {
        resultUpdateInlineEdit = {
          ...state.currentContentGroup,
          start_date: contentGroupDetail.start_date,
          due_date: contentGroupDetail.due_date
        }
      }
      return {
        ...state,
        loading: false,
        currentContentGroup: contentGroupDetail,
        content_loading: false
      }
    }

    case userConstants.ASSIGN_LABEL_IN_CONTENT: {
      let currentLabel = action.payload
      let resultLabelUpdate = ""
      if(state.currentContentGroup && state.currentContentGroup) {
        let contentGroup = [state.currentContentGroup]
        resultLabelUpdate = contentGroup && contentGroup.map((el, index) => {
          return { ...el, labels: currentLabel.data.content_labels };
        })
      }
      return {
        ...state,
        loading: false,
        currentContentGroup: resultLabelUpdate && resultLabelUpdate.reduce((a, b) => Object.assign(a, b), {}),
        content_loading: false
      }
    }

  default:
    return state
  }
}
