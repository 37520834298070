import { alertActions, contentActions, content_groupActions, work_spaceActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makeDELETERequest, makePUTRequest } from '../utils/Axios';
import { reset } from "redux-form";

export const contentLabelActions = {
  createContentLabel,
  getContentLabels,
  editContentLabel,
  deleteContentLabel,
  updateContentLabel
};

export function createContentLabel(workspaceId, reqparams, closeModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LABEL_LOADING });
      makePOSTRequest(`/api/v1/workspaces/${workspaceId}/labels`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(reset('content_label_form'));
          closeModal();
          dispatch({ type: userConstants.CONTENT_LABEL_REQUEST, payload: response.data.label})
          dispatch(alertActions.success(userConstants.CONTENT_LABEL_REQUEST));
          dispatch(work_spaceActions.showWorkSpace(workspaceId, true));
        }else {
          dispatch({ type: userConstants.CONTENT_LABEL_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_LABEL_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateContentLabel(workspaceId, reqparams, id, closeModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LABEL_LOADING });
      makePUTRequest(`/api/v1/workspaces/${workspaceId}/labels/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(reset('content_label_form'));
          closeModal();
          dispatch(alertActions.success(userConstants.UPDATE_CONTENT_LABEL));
          dispatch({
            type: userConstants.UPDATE_CONTENT_LABEL,
            payload: response.data.label
          });

          dispatch(work_spaceActions.showWorkSpace(workspaceId, true));
        }else{
          dispatch({ type: userConstants.CONTENT_LABEL_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_LABEL_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getContentLabels(workspaceId) {
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/workspaces/${workspaceId}/labels`)
      .then(response => {
        dispatch({
          type: userConstants.GET_CONTENT_LABEL,
          payload: response
        })
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_LABEL_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editContentLabel(workspaceId, id, openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LABEL_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspaceId}/content_labels/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_CONTENT_LABEL,
            payload: response
          })

          openModal({ show: true, id: id });
        }
        else{
          history.push("/");
          dispatch({ type: userConstants.CONTENT_LABEL_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })

    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_LABEL_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function deleteContentLabel(workspace_id, id, content_group_id) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/labels/${id}`)
      .then(response => {
        if(response.data.status === 'ok') {
          dispatch({
            type: userConstants.DELETE_CONTENT_LABEL,
            payload: response.data.label
          })
          dispatch(content_groupActions.getContentGroup(workspace_id));

          const getContentParams = {
            workspace_id,
            content_group_id
          };
          
          dispatch(contentActions.getContent(getContentParams));
          dispatch(alertActions.success(userConstants.DELETE_CONTENT_LABEL));
        }else{
          dispatch(alertActions.error(response.data.error))
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_LABEL_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
