import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { renderToString } from 'react-dom/server';
import { connect, useDispatch } from "react-redux";
import { ContentLabel, CardTag } from '../../../../components';
import { dateTimeFormatter, formatDay } from '../../../../utils';
import Calendar from '@toast-ui/react-calendar';
import { Button, Row, Col, Spinner } from "react-bootstrap";
import { content_groupActions, alertActions } from "../../../../actions";
import '@toast-ui/calendar/dist/toastui-calendar.css';
import DesktopCalendarCard from './desktopCalendarCard.js';

function DesktopCalendar(props) {
  const {
    workspace_id,
    date,
    setDate,
    onSelectDateTime,
    calendarRef,
    events,
    can_access_content,
    onClickEvent,
    custom_close_button_id_text,
    can_access_edit_label,
    can_access_move_content_calendar,
    content_group_loading
  } = props
  const dispatch = useDispatch();

  useEffect(() => {
    if(calendarRef.current){
      const calendarInstance = calendarRef.current.getInstance();

      calendarInstance.setDate(date);
      calendarInstance.render();
    }
  }, [calendarRef.current]);

  const themeConfig = {
    common:{
      holiday:{
        color: 'rgb(51, 51, 51)',
      },
      backgroundColor: 'white',
    },
    month:{
      dayName: {
        borderLeft: '0',
        backgroundColor: '#f4f1ec'
      },
      holidayExceptThisMonth: {
        color: 'rgb(51, 51, 51)'
      },
      dayExceptThisMonth: {
        color: 'rgb(51, 51, 51)'
      }
    }
  };

  const mapSchedule = (items) => {
    return items?.map(item => (
      item.due_date && {
        id: item.id,
        title: item.name,
        raw: {
          labels: item.labels,
          due_date: item.due_date,
          reminder_in_day: item?.reminder_in_day,
          completed: item?.completed,
          drafts: item?.drafts,
        },
        category: 'time',
        calendarId: 1,
        start: item.due_date && dateTimeFormatter(item.due_date, 'input'),
        end: item.due_date && dateTimeFormatter(item.due_date, 'input'),
        isReadOnly: false
      }
    )).filter(item => !!item);
  }

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.today();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.prev();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.next();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const handleClickRenderButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.render();
  }

  const updateContentGroup = (schedule, changes) => {
    let contentGroupId = schedule.id;

    const callback = () => {
      handleClickRenderButton();
    }

    let values = { content_group: { dueDate: "" + changes.end.d } };
    dispatch(content_groupActions.updateContentGroup(values, contentGroupId, workspace_id, callback));
  };

  const onBeforeUpdateEvent = function(event) {
    if(can_access_move_content_calendar) {
      var schedule  = event.event;
      var changes = event.changes;

      if(schedule.end.d.d.toLocaleDateString() === changes.end.d.d.toLocaleDateString()) return;

      updateContentGroup(schedule, changes);
    }else {
      dispatch(alertActions.error("You are not authorized to access this page"));
    }
  }

  const handleSeeMoreEvents = (date) =>{
    const { top, height, left } = date.target.getBoundingClientRect();

    const { innerWidth, innerHeight } = window;
    const padding = top - innerHeight;

    if(top < 0){
      return date.target.style.top = '51px';
    }

    if(innerHeight <= top + height){
      const beta_sum = top - (height + padding + 35);
      if(beta_sum >0) {
        return date.target.style.top = beta_sum + 'px';
      } else{
        return date.target.style.top = top - (height) + 'px';
      }
    }
  }

  return (
      <>
        <div className="calendar-button_group layout__adaptive-wrapper">
          <Row>
            <Col md="7" lg="9" className={"d-flex align-items-center"}>
              <h2 id="renderRange" className="render-range calendar__month mb-0">{ dateTimeFormatter(date, 'calendar_month')}</h2>
            </Col>
            <Col md="5" lg="3" className={"d-flex justify-content-end"}>
              <Button variant="" onClick={handleClickTodayButton} className="mx-1 btn-today">This Month</Button>
              <Button variant="" onClick={handleClickPrevButton} className="mx-1"><i className="fa fa-angle-left" aria-hidden="true"></i></Button>
              <Button variant="" onClick={handleClickNextButton} className="mx-1"><i className="fa fa-angle-right" aria-hidden="true"></i></Button>
            </Col>
          </Row>
        </div>

        <div
          className={`calendar-wrapper layout__adaptive-wrapper pt-0 ${content_group_loading && 'loading'}`}
          style={{ overflowY: "auto" }}
        >
          {
            content_group_loading &&
              <div className="clearfix">
                <div className="spinner-container">
                  <Spinner animation="border" />
                </div>
              </div>
          }
          <Calendar
            isReadOnly={!can_access_move_content_calendar || content_group_loading}
            useDetailPopup={false}
            onSelectDateTime={onSelectDateTime}
            onBeforeUpdateEvent={onBeforeUpdateEvent}
            month={{
              visibleEventCount: 1
            }}
            usageStatistics={false}
            ref={calendarRef}
            height="900px"
            events={ mapSchedule(events) }
            view= {'month'}
            gridSelection={{
              enableClick: can_access_content && !content_group_loading,
              enableDblClick: false
            }}
            taskView={true}
            theme={themeConfig}
            eventView={true}
            onClickEvent={onClickEvent}
            onClickMoreEventsBtn={handleSeeMoreEvents}
            template= {{
              time(schedule) {
                let projectLabels = schedule.raw?.labels.map((label, index) => (
                  renderToString(
                    <ContentLabel
                      id={label.id}
                      custom_close_button_id_text={custom_close_button_id_text}
                      editable={can_access_edit_label && !content_group_loading}
                      name={label.name}
                      color_hex={label.color_hex}
                      custom_close_button_bg_color={"#E9E8F5"}
                    />
                  )
                )).join('');

                let default_string = renderToString(
                    <DesktopCalendarCard schedule={schedule}/>
                )

                if(projectLabels){
                  default_string = default_string + "<div class='calendar__project-labels'>" + projectLabels + "</div>";
                }

                return default_string;
              },
              allday(schedule) {
                return "<span>foo</span>"; //JSON.stringify(schedule);
              },
              popupDetailDate(isAllDay, start, end) {
                var end_date = new Date(end).toISOString();
                return ("Due date: "+ dateTimeFormatter(end_date, 'calendar_date_time'));
              },
            }}
          />
        </div>
      </>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.work_space.loading,
    content_group_loading: state.content_group.content_group_loading,
    can_access_move_content_calendar: state.work_space.can_access_move_content_calendar
  };
};

export default connect(mapStateToProps, {  })(DesktopCalendar);
