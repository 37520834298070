import { imageExtentionsConstants } from "../../../../constants";
import { ImageAttachment } from "../../../../components";
import { FileAttachment } from "../../../../components";

function FormAttachmentThumbnails({
  uploadedFiles,
  handleDelete,
  commentToEdit
}) {

  return (
    <>
     <div className="image-attachment-container mb-2">
      {
        uploadedFiles && filterByImages(uploadedFiles, commentToEdit).map((file, index) => (
            (file && file.id) ?
            <ImageAttachment
              filename={ file.filename }
              thumbnail={ file.file_thumbnail }
              own={ true }
              destroyable={ true }
              hide={file?._destroy}
              key={ "image_"+file.id }
              CustomHandleDelete={ (e) => handleDelete(file) }
            />
          :
             <ImageAttachment
              filename={ file.file.name }
              thumbnail={ URL.createObjectURL(file.file) }
              own={ true }
              destroyable={ true }
              key={ "uploaded_image_"+index }
              CustomHandleDelete={ (e) => handleDelete(file) }
            />
        ))
      }
      </div>

      {
        uploadedFiles && filterByNotImages(uploadedFiles, commentToEdit).map((file, index) => (
          (file && file.id) ?
            <FileAttachment
              filename={ file.filename }
              hide={file?._destroy}
              own={ true }
              destroyable={ true }
              key={ "file_"+file.id }
              CustomHandleDelete={ (e) => handleDelete(file) }
            />
          :

          <FileAttachment
            filename={ file.file.name }
            own={ true }
            destroyable={ true }
            key={ "uploaded_file_"+index }
            CustomHandleDelete={ (e) => handleDelete(file) }
          />
        ))
      }
    </>
  );
}


export const filterByImages = (array, commentToEdit) => {
  if(array === undefined) return [];

  return array.filter(item => 
    item?.id ?
      (
        imageExtentionsConstants.some(extension => 
          item.filename.toLowerCase().endsWith(extension.toLowerCase())
        )
      )
    :
      imageExtentionsConstants.some(extension => 
        item.file.name.toLowerCase().endsWith(extension.toLowerCase())
      )
  );
  
};

export const filterByNotImages = (array, commentToEdit) => {
  if(array === undefined) return [];

  return array.filter(item => 
    item?.id ?
      (
        !imageExtentionsConstants.some(extension => 
          item.filename.toLowerCase().endsWith(extension.toLowerCase())
        )
      )
    :
      !imageExtentionsConstants.some(extension => 
        item.file.name.toLowerCase().endsWith(extension.toLowerCase())
      )
  );
};

export default FormAttachmentThumbnails;
