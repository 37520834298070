import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { notificationConstants } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import notificationSound from '../../../sounds/notification-ring.mp3';
import ActionCable from 'actioncable';
import { SoundContext } from '../../../providers/AudioProvider';

function NotificationActionCable(props) {
  const dispatch = useDispatch();
  const [play, { stop }] = useSound(notificationSound);
  const user_id = useSelector(state => state.dashboard.currentUser);
  const enable_notification_ring = useSelector(state => state.notification.enable_notification_ring);
  const [subscription, setSubscription] = useState(null);
  const cable = ActionCable.createConsumer(process.env.REACT_APP_CABLE_URL);
  const { audioContextReadyRef, needSoundRef } = useContext(SoundContext);

  const updateActionCable = async (payload) => {
    // wait this function until its finished
    await dispatch({ type: notificationConstants.GET_ACTION_CABLE_NOTIFICAIONS, payload: payload });

    // wait 500 ms
    setTimeout(() => playSound(payload), 500);
  };

  const playSound = (payload) => {
    const notification = payload;
        
    if(
        notification?.is_read === false && 
        notification?.summary !== "-" && 
        notification?.viewable && 
        enable_notification_ring && 
        notification?.is_sound_enabled
      ){
      if (audioContextReadyRef.current) {
        console.log("Notif sound")

        const audio = new Audio(notificationSound);
        audio.play();
      } else {
        console.log("Audio not ready")
      }
    }
  }
  
  const handleReceivedLists = useCallback( (res) => {
    if(res?.deleted){
      dispatch({ type: notificationConstants.DELETE_ACTION_CABLE_NOTIFICATIONS, payload: res.data })
    } else {
      updateActionCable(res?.data)
    }
  }, [enable_notification_ring]);

  useEffect(() => {
    let newSubscription = null;

    if(user_id){
      const channel = { channel: "NotificationChannel", user_id: user_id && user_id.id }
      newSubscription = cable.subscriptions.create(
        channel,
        {
          received: (res) => handleReceivedLists(res),
          connected: () => console.log('Connected to server.'),
          disconnected: () => console.log('Disconnected from NotificationChannel'),
        }
      );
  
      setSubscription(newSubscription);
    }

    return () => {
      // Unsubscribe when component unmounts or dependency changes
      if (newSubscription) {
        cable.subscriptions.remove(newSubscription);
        console.log('Unsubscribed from NotificationChannel');
      }
    };
  }, [user_id, enable_notification_ring]);

  return null
}

export default NotificationActionCable



