import { useState, useEffect, useRef, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import WorkspaceSearchResult from './WorkspaceSearchResult';
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick';
import { content_groupActions, search_historyActions } from '../actions';
import { SearchContext } from '../contexts/SearchContext';
import { useHistory } from 'react-router';
import { SearchIcon, CloseIcon } from './';
import { searchHistoryConstants, mediaQueryConstants } from '../constants';
import { useMediaQuery } from 'react-responsive';

function WorkspaceSearchBar(props) {
  const {
    currentWorkSpace,
    search_histories,
    mobile,
    mobileSearchActive,
    setMobileSearchActive
  } = props;
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });
  const windowHistory = useHistory();
  const dispatch = useDispatch();
  const searchResultRef = useRef(null);
  const { id } = currentWorkSpace ? currentWorkSpace : {};
  const [showResult, setShowResult] = useDetectOutsideClick(searchResultRef, false);
  const query = new URLSearchParams(window.location.search);
  const search_param = query.get('search')
  let default_search_text = search_param !== undefined && search_param !== null ? search_param : ''
  const [searchText, setSearchText] = useState(default_search_text);
  const [initialLoad, setInitialLoad] = useState(false);
  let { searchBarRef, searchTimeout } = useContext(SearchContext);

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      dispatch(search_historyActions.createSearchHistory(id, e.target.value));
      clearTimeout(searchTimeout);
      setShowResult(false);

      if (e.target.value === '' || e.target.value === null){
        windowHistory.push({
          pathname: window.location.pathname,
          search: ``
        })
      } else {
        windowHistory.push({
          pathname: window.location.pathname,
          search: `?search=${e.target.value}`
        })
      }
    }
  }

  const recoverHistory = () =>{
    let history_storage = localStorage.getItem(searchHistoryConstants.KEY);

    if (history_storage !== 'undefined'){
      let data = JSON.parse(history_storage)
      dispatch(content_groupActions.setSearchHistoryContentGroup(data))
    } else {
      dispatch(content_groupActions.setSearchHistoryContentGroup([]))
    }
  }

  const handleResetSearch = () => {
    clearTimeout(searchTimeout);
    setShowResult(false)
    searchBarRef.current.value = ''
    setSearchText('')
    if (id) {
      dispatch(search_historyActions.createSearchHistory(id, '')); 
    }

    windowHistory.push({
      pathname: window.location.pathname,
      search: ''
    })
  }

  const handleChange = (e) =>{
    setSearchText(e.target.value);
  }

  const handleFocus = (e) =>{
    setShowResult(true);
    dispatch(content_groupActions.recommendContentGroup(id, e.target.value));
  }

  useEffect(() => {
    if(id !== undefined){
      dispatch(search_historyActions.getSearchHistories(id));
    }
  }, []);

  useEffect(() => {
    if(id && initialLoad === false){
      dispatch(search_historyActions.createSearchHistory(id, default_search_text));
      setInitialLoad(true);
    }
  }, [id, default_search_text]);

  useEffect(() => {
    if(id !== undefined){
      dispatch(search_historyActions.getSearchHistories(id));
    }
  }, [id]);

  useEffect(() => {
    if (initialLoad){
      searchTimeout = setTimeout(
          function() {
            if(id){
              dispatch(content_groupActions.recommendContentGroup(id, searchText))
            }
            if (searchText == ''){
              setShowResult(false);
            } else {
              setShowResult(true);
            }
          }
          .bind(this),
          3000
      );
    }

    return () => clearTimeout(searchTimeout)
  }, [searchText]);

  useEffect(() => {
    if(mobile){
      if (mobileSearchActive) {
        searchBarRef.current.focus();
      } else {
        handleResetSearch();
      }
    }
  }, [mobileSearchActive]);


  const workspaceSearchBarClass = () =>{
    let defaultClass = `${searchBarRef?.current?.value !== '' ? 'active' : ''} workspace_search-bar align-items-center px-2`;

    if(isLargeScreen){
      return defaultClass + " mr-3 " + ` ${mobileSearchActive ? '' : 'd-none'}`
    } else {
      return defaultClass + " mr-4 "
    }
  }

  return (
    <div
      className={`workspace_search-bar_container align-items-center d-flex ${ mobile ? 'justify-content-end' : '' }`}
      ref={searchResultRef}
    >
      <div
        className={workspaceSearchBarClass()}
      >
        <SearchIcon  height={18} width={18} className={'mr-2'}/>

        <input
          ref={searchBarRef}
          onChange={handleChange}
          onKeyDown={handleSearch}
          placeholder={'Search by card title...'}
          defaultValue={searchText}
          onFocus={handleFocus}
        />
        {searchBarRef?.current?.value !== '' &&
          <div className="workspace_search-bar_close-button text-center" onClick={handleResetSearch}>
            <CloseIcon className="btn-close" />
          </div>
        }
      </div>

      <div className={`d-flex workspace_search-sort align-items-center justify-content-center d-none ${showResult && 'active'}`}>
        <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
      </div>


      {
        mobileSearchActive ?
          <div
            className="workspace_search-bar_mobile_search_toggle search_close_icon-btn cursor-pointer"
            onClick={() => setMobileSearchActive(false)}
          >
            <CloseIcon
            />
          </div>
        :
          <div
            className="workspace_search-bar_mobile_search_toggle cursor-pointer"
            onClick={() => setMobileSearchActive(true)}
          >
            <SearchIcon
              height={"20"}
              width={"20"}
            />
          </div>
      }

      {
        showResult &&
        <WorkspaceSearchResult
          search_histories={search_histories?.filter(sh => sh['text'] !== '')}
          workspace_id={id}
          setShowResult={setShowResult}
        />
      }
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    currentWorkSpace: state.work_space.currentWorkSpace,
    search_histories: state.search_history.search_histories
  };
};

export default connect(mapStateToProps, { content_groupActions })(WorkspaceSearchBar);
