import React, { useState, useEffect, useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { history } from '../../../helpers';
import { Navbar, Container, Nav, NavDropdown, Offcanvas, Image } from 'react-bootstrap';
import { userActions, dashboard_userActions, work_spaceActions, content_groupActions } from '../../../actions';
import { UserAvatar } from '../../../components';
import NotificationsDropdown from '../NotificationPage/dropdown';
import UserProfileForm from '../UserProfile/userProfileForm';
import logoWhite from '../../../images/logo/UPP-Logotype-Landscape-White-Transparent.png';
import ContentList from "../ContentPage/list";
import OrganizationDropdown from './organization_dropdown';
import { UpgradeButton } from '../../../components';
import { TopbarContext } from "../../../contexts/TopbarContext";
import { mediaQueryConstants } from '../../../constants';
import { useMediaQuery } from 'react-responsive';
import LogoutIcon from '../../../images/icons/logout-icon.svg';
import PermissionIcon from '../../../images/icons/permission.svg';
import ProfileIcon from '../../../images/icons/profile.svg';

function HeaderSection(props) {
  const dispatch = useDispatch();
  const {
          setSidebar,
          setWorkspaceContentGroupPopUp,
          current_organization,
          currentWorkSpace,
          can_create_organization
        } = props
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });
  const notificationCount = useSelector(state => state.dashboard.notificationCount);
  const unread_notificationCount = useSelector(state => state.notification.unread_notificationCount);
  const currentUser = useSelector(state => state.dashboard.currentUser);
  const [ contentGroupPopUp, setContentGroupPopUp] = useState({id: null, show: false });
  const [highlighted_comment, setHighlightedComment] = useState({id: null, internal: false, cg_id: null});
  const [ workspaceId, setWorkspaceId] = useState();
  const [ workspace_update, set_workspace_update] = useState();
  const [ access_content, set_access_content] = useState();
  const [ access_content_edit, set_access_content_edit] = useState();
  const user_json = localStorage.getItem("user")

  function logout(response) {
    dispatch(userActions.logout());
  }

  const {
    setActiveOffCanvas,
    showNotificationsDropdown,
    setShowNotificationsDropdown,
  } = useContext(TopbarContext);

  useEffect(() => {  
    dispatch(dashboard_userActions.CommonInitialData())
  }, [user_json]);

  const isUserLoggedIn = localStorage.getItem('user') ? true : false;
  // const email = localStorage.getItem('email');
  // const fname = localStorage.getItem('fname');
  // const profile_img = localStorage.getItem('profile_img');
  const isUserRole = localStorage.getItem('user_role')

  const openOffcanvas = () => {
    setActiveOffCanvas(prev => !prev)
  }

  const offcanvasDesktop = () => {
    document.body.classList.toggle('desktop_active');
    //setSidebar();
  }

  const [popupNewForm, setPopUpNewForm] = useState({
    show: false
  });;

  const handleCloseUserProfileForm = () => {
    setPopUpNewForm({show: false});
  }

  const handleShowNewForm = () => setPopUpNewForm({show: true});

  document.onkeydown = function (evt) {
    if (evt.keyCode == 27) {
      if (!contentGroupPopUp.show) {
        setShowNotificationsDropdown(false)
      }
    }
  };

  const handleModalCloseNotification = (content_id, workspace_id, comment_id, comment_internal, cg_id, work_space_update, can_access_content, can_access_edit_title) => {
    setWorkspaceContentGroupPopUp({id: null, show: false})
    setWorkspaceId(workspace_id)
    set_workspace_update(work_space_update)
    set_access_content(can_access_content)
    set_access_content_edit(can_access_edit_title)
    setContentGroupPopUp({id: content_id, show: true})
    setHighlightedComment({id: comment_id, internal: comment_internal, cg_id: cg_id})
  }

  const handleInlineEdit = (handleOnBlur, values, content_group_id) => {
    dispatch(content_groupActions.inlineEditContentGroup(handleOnBlur, values, content_group_id, workspaceId, true));

  }

  const handleCloseContentGroupPopUp = () => {
    sessionStorage.setItem("content_popup_notification", false)

    setContentGroupPopUp({id: null, show: false})
    setHighlightedComment({id: null, internal: false, cg_id: null})
  }

  return (
    <div>
      <div className="layout__top-bar">
        {
          isUserLoggedIn
            ?
            <div className="header_iner ">
              <div
                className="container-fluid d-flex justify-content-between align-items-center"
                style={{paddingBottom: 0, paddingTop: 0}}
              >
                <Nav className="header__workspace-dropdown justify-content-end align-items-center">
                  <div>
                    <div className="sidebar_icon d-lg-none" onClick={openOffcanvas}>
                      <i className="fa fa-bars"></i>
                    </div>
                    <a href="/" className="logo__text mobile-none">
                      <img src={ logoWhite } title="Upprovall" alt="Upprovall" height="20" />
                    </a>
                  </div>

                  <OrganizationDropdown  hidden={isLargeScreen} />

                  {
                    isUserRole === "admin" && current_organization &&
                    <>
                      <Nav.Link href={`/organizations/${current_organization.id}/admin/board-stages`} className="px-1 mobile-none">
                        Board Stages
                      </Nav.Link>
                      <Nav.Link href={`/organizations/${current_organization.id}/admin/users`} className="px-1 mobile-none">
                        Users
                      </Nav.Link>
                    </>
                  }
                </Nav>


                <Nav className="header__user-dropdown ml-auto justify-content-end align-items-center">
                  {
                    currentWorkSpace && !can_create_organization && current_organization?.personal && !isLargeScreen &&
                    <UpgradeButton
                      upgrade_button_style="small"
                    />
                  }

                  {
                    !isLargeScreen &&
                    <>
                      <Nav.Link onClick={() => setShowNotificationsDropdown(!showNotificationsDropdown)} className={"notification-link mr-4 d-flex align-items-center" + (showNotificationsDropdown ? " active" : "")} style={{position: 'relative'}} >
                        <i className="fa fa-bell">
                          <sub>{notificationCount && notificationCount !== 0 ? notificationCount : null }</sub>
                        </i>
                        {unread_notificationCount && unread_notificationCount !== 0 ? <span className="unread_badge" /  > : null}
                      </Nav.Link>
                      </>
                    }

                    <div className={`${showNotificationsDropdown ? '' : 'd-none'}`}>
                      <div className="notification-backdrop" onClick={() => setShowNotificationsDropdown(!showNotificationsDropdown)}/>
                      <NotificationsDropdown
                        handleHideNotificationsDropdown={() => setShowNotificationsDropdown(false)}
                        handleModalCloseNotification={handleModalCloseNotification}
                        showNotificationsDropdown={showNotificationsDropdown}
                      />
                    </div>


                  <NavDropdown
                    title={
                      <div className="user-name">
                        { currentUser &&
                          <UserAvatar icon={ currentUser.logo && currentUser.logo.url } name={ currentUser.name } name_initials={ currentUser.name_initials } size="35" />
                        }
                      </div>
                    }
                    id="basic-nav-dropdozwn"
                    onClick={() => setShowNotificationsDropdown(false)}
                  >
                    <NavDropdown.Item href="#" className={"d-lg-none"}>
                      <div className="row">
                        <div className="col-2">
                          <i
                            className="fa fa-user"
                            aria-hidden="true"
                          >
                          </i>
                        </div>
                        <div className="col-9">
                          {currentUser && currentUser.first_name ? currentUser && currentUser.first_name : currentUser && currentUser.email }
                        </div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleShowNewForm}>
                      <div className="row">
                        <div className="col-2">
                          <Image src={ProfileIcon} height={"16"}/>
                        </div>
                        <div className="col-9">
                          Profile
                        </div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/permission">
                      <div className="row">
                        <div className="col-2">
                          <Image src={PermissionIcon} height={"16"}/>
                        </div>
                        <div className="col-9"> View Permissions</div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={logout}>
                      <div className="row">
                        <div className="col-2">
                          <Image src={LogoutIcon} height={'12'}/>
                        </div>
                        <div className="col-9">
                          Log Out
                        </div>
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </div>
            </div>
          :null
        }

        <UserProfileForm handleCloseUserProfileForm = {handleCloseUserProfileForm} showForm={popupNewForm.show}/>
      </div>
      { contentGroupPopUp.show &&
        <div style={{zIndex: 1024}}>
          <ContentList
            content_group_id={contentGroupPopUp.id}
            contentGroupPopUp={contentGroupPopUp}
            setContentGroupPopUp={setContentGroupPopUp}
            workspace_id={workspaceId}
            updated_at={workspace_update}
            handleCloseContentGroupPopUp={handleCloseContentGroupPopUp}
            handleInlineEdit={handleInlineEdit}
            can_access_content={access_content}
            can_access_edit_title={access_content_edit}
            highlighted_comment={highlighted_comment}
            content_popup_notification="show_content_notification"
          />
        </div>
      }

    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    current_organization: state.organization.current_organization,
    currentWorkSpace: state.work_space.currentWorkSpace,
    can_create_organization: state.organization.can_create_organization
  };
};

export default connect(mapStateToProps, { dashboard_userActions })(HeaderSection);
