import { Row, Col } from 'react-bootstrap';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

function ReplyContainer(props) {
  const { data, scrollToComment, setCommentToReplyId, handleClose } = props

  return (
    <div
      className="message message--reply"
      onClick={() => scrollToComment && scrollToComment(data.id) }
    >
      <div className="d-flex justify-content-between">
        {
          handleClose == undefined &&
          <div className="d-flex">
            <i className="fa fa-arrow-right m-auto" aria-hidden="true"></i>
          </div>
        }
        <div style={{width: '95%'}} className="px-2">
          <div className="user">
            { data.user }
          </div>
          {
            data.description &&
            <div className= "comment-to-reply-container">
              <div className="description_html" dangerouslySetInnerHTML={{ __html:
                linkifyHtml((data.description || ''), { target: '_blank' })
              }} />
            </div>
          }

          {
            data.attachments?.length > 0 &&

            <span>
              {`${data.attachments.length} attachment${data.attachments.length >= 2 ? 's' : ''}`}
            </span>

          }
        </div>

        {
          handleClose &&

            <div
              className="d-flex p-0"
            >
                <i className="fa fa-times m-auto " aria-hidden="true" onClick={handleClose}></i>
            </div>
        }
      </div>
    </div>
  );
}

export { ReplyContainer };
