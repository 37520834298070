import React, { useEffect, useState } from 'react';

export function useMinuteCheck(date, timeSpan = 300) {
  const loadTime = date ? date : Date.now();
  const minutesInSeconds = timeSpan; // 5 minutes as default
  const [hasMinutesPassed, setHasMinutesPassed] = useState(hasPassed(Date.now(), loadTime, minutesInSeconds));


  useEffect(() => {
      const checkTime = () => {
          const currentTime = Date.now(); // Get current time again
          if (hasPassed(currentTime, loadTime, minutesInSeconds)) {
            setHasMinutesPassed(true); // Update state if 5 minutes have passed
          } else {
            setHasMinutesPassed(false);
          }
      };

      const intervalId = setInterval(checkTime, 1000); // Check every second

      return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return hasMinutesPassed;
}

function hasPassed(currentTime, loadTime, minutesInSeconds){
  return (currentTime - loadTime) / 1000 >= minutesInSeconds
}