import { Capitalization } from "./capitalization";

export function getChatPreText(e) {
  var element = e.currentTarget;
  handleCapitalization(e);
  var caretOffset = 0;
  if (typeof window.getSelection != "undefined") {
    var range = window.getSelection().getRangeAt(0);
    var preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
  }

  let pretext = e.currentTarget.textContent.substring(0, caretOffset);
  let tag = pretext.match(/@([a-zA-Z0-9]+)$/)

  return { pretext, tag }
}

const handleCapitalization = (event) => {
  if(event.target.innerHTML.length > 1) return;

  let string = event.target.innerHTML;
  event.target.innerHTML = Capitalization(string);

  let range = document.createRange();
  range.selectNodeContents(event.target);
  range.collapse(false);
  let selection = window.getSelection();
  selection.removeAllRanges();

  const textLength = event.target.innerHTML.length;
  selection.addRange(range);
  event.target.focus();
}
