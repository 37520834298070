import { Spinner } from 'react-bootstrap';

function LoadSpinner(spinner = false) {


  return (
    <div id="overlay" class="d-flex flex-column align-items-center">

      {
        spinner &&
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      
    </div>
  );
}

export { LoadSpinner };
