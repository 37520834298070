function CircleAddIcon(props) {
  const { className, color, height, width } = props

  return (
    <svg
      id={"Layer_1"}
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      viewBox={"0 0 14 14"}
      height={ height ? height : "14" }
      width={ width ? width : "14" }
      fill={color}
      className={className}
    >
      <path className="st0" d="M6.32,7.68v2.11c0,.19.06.35.19.48.13.13.29.19.48.19s.35-.06.48-.19c.13-.13.2-.29.2-.48v-2.11h2.11c.19,0,.35-.06.48-.19s.19-.29.19-.48-.06-.35-.19-.48c-.13-.13-.29-.2-.48-.2h-2.11v-2.11c0-.19-.06-.35-.19-.48-.13-.13-.29-.19-.48-.19s-.35.06-.48.19c-.13.13-.2.29-.2.48v2.11h-2.11c-.19,0-.35.06-.48.19-.13.13-.19.29-.19.48s.06.35.19.48.29.2.48.2c0,0,2.11,0,2.11,0ZM7,14c-.97,0-1.87-.18-2.72-.55-.85-.36-1.59-.87-2.23-1.5-.64-.64-1.14-1.38-1.5-2.23-.36-.85-.55-1.76-.55-2.73s.18-1.88.55-2.72c.36-.84.87-1.59,1.5-2.22.64-.64,1.38-1.14,2.23-1.5.85-.36,1.76-.55,2.73-.55s1.88.18,2.72.55c.84.36,1.59.87,2.22,1.5.64.64,1.14,1.38,1.5,2.23s.55,1.75.55,2.72-.18,1.87-.55,2.72c-.36.85-.87,1.59-1.5,2.23-.64.64-1.38,1.14-2.23,1.5-.85.36-1.75.55-2.72.55ZM7,12.52c1.54,0,2.84-.54,3.91-1.61,1.07-1.07,1.61-2.38,1.61-3.91s-.54-2.84-1.61-3.91c-1.07-1.07-2.38-1.61-3.91-1.61s-2.84.54-3.91,1.61c-1.07,1.07-1.61,2.38-1.61,3.91,0,1.54.54,2.84,1.61,3.91,1.07,1.07,2.38,1.61,3.91,1.61Z"/>
    </svg>
  );
}

export { CircleAddIcon };
