function ApprovalCommentIcon(props) {
  const { className, height, width, style } = props

  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "24"}
      className={className}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M8.25 8C7.15 8 6.20833 7.60833 5.425 6.825C4.64167 6.04167 4.25 5.1 4.25 4C4.25 2.9 4.64167 1.95833 5.425 1.175C6.20833 0.391667 7.15 0 8.25 0C9.35 0 10.2917 0.391667 11.075 1.175C11.8583 1.95833 12.25 2.9 12.25 4C12.25 5.1 11.8583 6.04167 11.075 6.825C10.2917 7.60833 9.35 8 8.25 8ZM2.25 16C1.7 16 1.22917 15.8042 0.8375 15.4125C0.445833 15.0208 0.25 14.55 0.25 14V13.2C0.25 12.65 0.391667 12.1333 0.675 11.65C0.958333 11.1667 1.35 10.8 1.85 10.55C2.7 10.1167 3.65833 9.75 4.725 9.45C5.79167 9.15 6.96667 9 8.25 9H8.9125C9.12083 9 9.33333 9.01667 9.55 9.05C9.88333 9.08333 10.1 9.25833 10.2 9.575C10.3 9.89167 10.2333 10.1667 10 10.4L9.575 10.825C9.05833 11.3417 8.76667 11.95 8.7 12.65C8.63333 13.35 8.79167 13.9917 9.175 14.575C9.375 14.8917 9.40417 15.2083 9.2625 15.525C9.12083 15.8417 8.88333 16 8.55 16H2.25ZM13.8 13.6L18.15 9.25C18.3333 9.06667 18.5667 8.975 18.85 8.975C19.1333 8.975 19.3667 9.06667 19.55 9.25C19.7333 9.43333 19.825 9.66667 19.825 9.95C19.825 10.2333 19.7333 10.4667 19.55 10.65L14.5 15.7C14.3 15.9 14.0667 16 13.8 16C13.5333 16 13.3 15.9 13.1 15.7L11.05 13.65C10.8667 13.4667 10.775 13.2333 10.775 12.95C10.775 12.6667 10.8667 12.4333 11.05 12.25C11.2333 12.0667 11.4667 11.975 11.75 11.975C12.0333 11.975 12.2667 12.0667 12.45 12.25L13.8 13.6Z"/>
    </svg>
  );
}

export { ApprovalCommentIcon };
