import React, { useState, useContext, useEffect } from 'react';
import { adminBaseURL } from '../utils/commonUtils';
import { commentAttachmentActions } from '../actions';
import { useDispatch, connect } from 'react-redux';
import { ChatContext } from '../contexts/ChatContext'
import { Popup } from './Popup';

function FileAttachment(props) {
  const dispatch = useDispatch();
  const {
    filename,
    workspace_id,
    content_group_id,
    comment_id,
    attachment_id,
    setSelected,
    own,
    downloadable,
    destroyable,
    CustomHandleDelete,
    show_x_btn,
    confirm_deletion,
    deleted_comment_attachment
  } = props

  const { activeTab } = useContext(ChatContext)
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [hidden, setHidden] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);

  useEffect(() => {
    if(deleted_comment_attachment && deleted_comment_attachment?.id === attachment_id){
      setHidden(true);
    }
  }, [deleted_comment_attachment]);
   
  const downloadAttachment = () => {
    if(downloadable){
      dispatch(
        commentAttachmentActions.downloadCommentAttachment(
          workspace_id,
          content_group_id,
          comment_id,
          attachment_id,
          filename
        )
      );
    }
  }

  const handleMouseOver = () => {
    if(setSelected) setSelected(true);
  };

  const handleMouseOut = () => {
    if(setSelected) setSelected(false);
  };

  const handleCloseDeletePopup = () => {
    setShowPopupDelete(false)
  }

  const handleDelete = (e) => {
    e.stopPropagation();

    if(CustomHandleDelete){
      CustomHandleDelete()
    } else {
      setSelected(false);

      dispatch(
        commentAttachmentActions.destroyCommentAttachment(
          workspace_id,
          content_group_id,
          comment_id,
          attachment_id,
          activeTab
        )
      );
    }
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if(confirm_deletion){
      setShowPopupDelete(true) 
    }else {
      handleDelete(e)
    }
  }


  if (hidden){
    return null
  }

  return (
    <div className={`attachment-container mb-2 file`}>
      <div
        className={`filename-container ${show_x_btn ? 'show_x_btn' : undefined}`}
        onClick={() => downloadAttachment()}
        onMouseOver={handleMouseOver}
        onMouseOut ={handleMouseOut}
      >
        <div className="filename-text">
          <i className="fa fa-file" aria-hidden="true"></i>
          { filename }
        </div>

        {
          own && destroyable &&
          <div className="close-button" onClick={handleDeleteClick}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        }
      </div>

      {showPopupDelete &&
        <Popup
          handleClose={handleCloseDeletePopup}
          handleDelete={handleDelete}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    deleted_comment_attachment: state.comment.deleted_comment_attachment,
  };
};

export default connect(mapStateToProps, { })(FileAttachment);