export const notificationConstants = {
  GET_ALL_SUCCESS: 'Get all notifications success',
  GET_ALL_FAILURE: 'Get all notifications failure',

  READ_SUCCESS: 'Read notification success',
  UNREAD_SUCCESS: 'Unread notification success',
  READ_FAILURE: 'Read notification failure',

  READ_ALL_SUCCESS: 'Read all notification success',
  READ_ALL_FAILURE: 'Read all notification failure',
  GET_ACTION_CABLE_NOTIFICAIONS: 'get action cable notifications',
  UNREAD_NOTIFICAITON_COUNT: 'unread notifcation count',
  WORKSPACE_SHOW_FROM_NOTIFICATION: 'Show workspace from notification',
  DELETE_ACTION_CABLE_NOTIFICATIONS: 'DELETE_ACTION_CABLE_NOTIFICATIONS',
  UPDATE_NOTIFICATION_RING: 'Updated notification ring successfully',
  UPDATE_NOTIFICATION_RING_FAILURE: 'Update notification ring failed',
};
